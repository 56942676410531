import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {

  constructor() { }
}


export interface Document {
  files: File[],
  name: string;
  description: string;
  docSource: string;
  submitted: boolean;
}

export const DocumentType: Array<string> = [
  'Ration Card', 'Telephone Bill', 'Electricity Bill', 'Voters Card',
  'Aadhaar Card', 'Driving License', 'Employers Card',
  'Latest Bank Statement', 'Passbook', 'Salary Slip',
  'Form-16', 'Property Deed', 'Rent Agreement', 'Other'
];