import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppEndPoint } from '../../../share/lib/api-constant';
import { CommonResource ,FormService} from '../../../share/services';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.scss']
})
export class ForgetPasswordComponent implements OnInit {
  forgetForm: FormGroup ;

get forgetPass() { return this.forgetForm.controls; }
get email() { return this.forgetForm.get('email'); }

  constructor(
    private commonResource: CommonResource,
    public formService:FormService,
    public router: Router) { 
      
    }

  ngOnInit(): void {
this.forgetForm=new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email
    ])
  });
  }


  send() {    
    this.formService.markDirty(this.forgetPass);
    if (this.forgetForm.invalid) {
    return;
    }
      this.commonResource.post(AppEndPoint.USERS + 'forgetPassword', {
        email: this.email.value
      }).then((data: any) => {
         this.router.navigate(['auth/login']);
      });
  }

  
}
