import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FollowupService {

  constructor() { }
}


export abstract class FollowupTypes {
  public static readonly EMAIL: string = 'EMAIL';
  public static readonly PHONE = 'PHONE';
  public static readonly MEETING = 'MEETING';
  public static readonly UNABLE_TO_CONTACT = 'UNABLE_TO_CONTACT';
  public static readonly OTHER = 'OTHER';
}

export abstract class FollowupStatus {
  public static readonly SCHEDULE: string = 'SCHEDULE';
  public static readonly CLOSED: string = 'CLOSED';
}
