import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Import Containers
import { AppLayoutComponent, P404Component, P401Component } from './share/components';
export const routes: Routes = [
  {
    path: '',
    component: P404Component
  },
  {
    path: 'user',
    component: AppLayoutComponent,
    loadChildren: () => import('./user/user.module').then(m => m.UserModule),
  },

  {
    path: 'admin',
    component: AppLayoutComponent,
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },

  {
    path: 'backend',
    component: AppLayoutComponent,
    loadChildren: () => import('./backend/backend.module').then(m => m.BackendModule),
  },

  {
    path: 'referal',
    component: AppLayoutComponent,
    loadChildren: () => import('./referal/referal.module').then(m => m.ReferalModule),
  },
  {
    path: 'unauthorized',
    component: P401Component
  },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MainRoutingModule { }
