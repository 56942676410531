import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AppEndPoint } from '../../../share/lib/api-constant';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertTypes, AlertService, FormService, CommonResource, UserTypes, StorageService, AuthService } from '../../../share/services';
@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;

  get signup() { return this.signupForm.controls; }

  constructor(
    private _formBuilder: FormBuilder,
    private commonResource: CommonResource,
    private storageService: StorageService,
    private authService: AuthService,
    public formService: FormService,
    public alertService: AlertService,
    public router: Router
  ) { }


  ngOnInit(): void {
    this.signupForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      name: ['', Validators.required],
      mobileNo: ['', [Validators.required, Validators.maxLength(10
      ), Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      city: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  public signupUser() {
    if (this.signupForm.invalid) {
      return;
    }
    const details: any = {};
    for (let control in this.signup) {
      details[control] = this.signup[control].value;
    }
    details['userRole'] = UserTypes.REFERAL;
    this.commonResource.post(AppEndPoint.USERS + 'signup', details).then((data: any) => {
      this.authService.setToken(data.token);
      delete data.token;
      this.storageService.setItem('userInfo', data);
      this.alertService.showAlert({
        message: 'Login successfully !',
        timeout: 3000,
        type: AlertTypes.SUCCESS,
        dismissible: true
      });
      this.router.navigate(['app/referal/dashboard']);
    });
  }
}
