import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from '@env/environment';
import { AlertService, AlertTypes } from '../services';
import { LoaderService } from '../services/loader/loader.service';

@Injectable({
  providedIn: 'root'
})
export class RequestInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private alertService: AlertService,
    private loaderService: LoaderService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.showLoader();
    let tokenDetails = localStorage.getItem('token') ? localStorage.getItem('token') : '';
    if (tokenDetails) {
      tokenDetails = JSON.parse(tokenDetails);
      if (!/^(http|https):/i.test(request.url)) {
        let url = '';
        url = environment.api_endpoint_url + request.url;
        const headers: any = {
          'Authorization': `${tokenDetails}`,
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache'
        }
        request = request.clone({
          url,
          headers: new HttpHeaders(headers)
        });
      } else {
        request = request.clone({
          setHeaders: {
            Authorization: `${tokenDetails}`,
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache'
          }
        });
      }
    } else {
      if (!/^(http|https):/i.test(request.url)) {
        request = request.clone({
          url: environment.api_endpoint_url + request.url,
        });
      }
    }
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.hideLoader();
        this.onEnd(event);
      }
    },
      (err: any) => {
        this.hideLoader();
        switch (err.status) {
          case 0: {
            this.alertService.showAlert({
              message: 'Server Down',
              timeout: 3000,
              type: AlertTypes.DANGER,
              dismissible: true
            });
          }
          case 404: {

          }
          case 401: {
            if (err.status == 401 && err.statusText == 'Unauthorized') {
              localStorage.clear();
              this.router.navigateByUrl('/auth/login');
              this.alertService.showAlert({
                message: 'Unauthorized Access!',
                timeout: 5000,
                type: AlertTypes.DANGER,
                dismissible: true
              });
            }
          }
        }
      }));
  }

  private showLoader(): void {
    this.loaderService.show();
  }

  private hideLoader(): void {
    this.loaderService.hide();
  }

  private onEnd(response: any): void {

    if (response.body && response.body.errors) {
      for (let key in response.body.errors) {
        this.alertService.showAlert({
          message: response.body.errors[key],
          timeout: 5000,
          type: AlertTypes.DANGER,
          dismissible: true
        });
      }
    }
  }
}