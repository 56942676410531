import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { SubSink } from 'subsink';
import { AlertService, AlertMessage, getAlertConfig } from '@modules/share/services';
import { AlertConfig } from 'ngx-bootstrap/alert';
@Component({
  // tslint:disable-next-line
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [{ provide: AlertConfig, useFactory: getAlertConfig }]
})
export class AppComponent implements OnInit, OnDestroy {
  subscription = new SubSink();
  alertsDismiss: Array<AlertMessage> = [];
  constructor(
    private router: Router,
    private alertService: AlertService
  ) { }

  ngOnInit() {
    this.subscription.add(
      this.router.events.subscribe((evt) => {
        if (!(evt instanceof NavigationEnd)) {
          return;
        }
        window.scrollTo(0, 0);
      }),
      this.alertService.alertSubject.subscribe((message: AlertMessage) => {
        this.alertsDismiss.push(message);
      }),
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
