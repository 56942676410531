import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AppEndPoint } from '../../../share/lib/api-constant';
import { AlertService, AlertTypes, CommonResource, FormService, AuthService, StorageService } from '../../../share/services';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  get login() { return this.loginForm.controls; }
  get userName() { return this.loginForm.get('userName'); }
  get password() { return this.loginForm.get('password'); }

  constructor(
    private commonResource: CommonResource,
    private authService: AuthService,
    private alertService: AlertService,
    private storageService: StorageService,
    public formService: FormService,
    public router: Router) {

  }

  ngOnInit(): void {
    this.loginForm = new FormGroup({
      userName: new FormControl('', [
        Validators.required,
        Validators.minLength(4)
      ]),
      password: new FormControl('', Validators.required),
    });
  }


  loginUser() {
    this.formService.markDirty(this.login);
    if (this.loginForm.invalid) {
      return;
    }
    this.commonResource.post(AppEndPoint.USERS + 'login', {
      email: this.userName.value,
      password: this.password.value
    }).then((data: any) => {
      // console.log(data);
      if (data && data.token) {
        this.authService.setToken(data.token);
        delete data.token;
        this.storageService.setItem('userInfo', data);
        this.alertService.showAlert({
          message: 'Login successfully !',
          timeout: 3000,
          type: AlertTypes.SUCCESS,
          dismissible: true
        });
        this.authService.loginRedirect();
      }
    }, (error: any) => {
      if (error.status && error.status == 404) {
        this.alertService.showAlert({
          message: 'User Not found!',
          timeout: 5000,
          type: AlertTypes.DANGER,
          dismissible: true
        });
      }
    });
  }


}
