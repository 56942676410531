import { BehaviorSubject } from 'rxjs';

export class ProfileService {
  private userProfile: Profile = null;
  private userProfilePromise = new BehaviorSubject<Profile>(this.userProfile);
  public $userProfilePromise = this.userProfilePromise.asObservable();

  public get profile() {
    return this.userProfile;
  }

  constructor() {
    let profile: any = localStorage.getItem('userInfo');
    if (profile) {
      this.userProfile = profile;
      this.userProfilePromise.next(JSON.parse(profile));
    }
  }

  public updateProfile(profile: Profile) {
    this.userProfile = profile;
    this.userProfilePromise.next(profile);
  }
}



export interface Profile {
  company_name: string;
  company_type: string;
  address: string;
  city: string;
  contactno: string;
  email: string;
  fname: string;
  password: string;
  pin: number;
  lname: string;
  gender: string;
  date_of_birth: string;
  status: string;
  user_id: number;
  user_role: string;
}