export const COMPANY_TYPES = [
    { value: '', type: 'Select Company Type' },
    { value: 'STATE_GOVT', type: 'State Govt.' },
    { value: 'CENTRAL_GOVT', type: 'Central Govt.' },
    { value: 'PROPRIETORSHIP', type: 'Proprietorship' },
    { value: 'PARTNERSHIP', type: 'Partnership' },
    { value: 'PVT_LTD', type: 'Pvt Ltd' },
    { value: 'PUBLIC_LTD', type: 'Public Ltd' },
    { value: 'HUF', type: 'HUF' },
    { value: 'LLP', type: 'LLP' }
];

export const LOAN_TYPES = [
    { value: '', type: 'Select Loan Type' },
    { value: 'Personal_Loan', type: "Personal Loan" },
    { value: 'Home_Loan', type: "Home Loan" },
    { value: 'Car_Loan', type: "Car Loan" },
    { value: 'LAP_Loan', type: "Loan Against Property" },
];

export const GENDERS = [
    { value: '', type: 'Select Gender' },
    { value: 'M', type: 'Male' },
    { value: 'F', type: 'Female' },
    { value: 'T', type: 'Transgender' },
];

export const SALARY_BANKS = [
    { value: '', type: 'Select Bank' },
    { value: 'HDFC_BANK', type: 'HDFC BANK' },
    { value: 'ICICI_BANK', type: 'ICICI BANK' },
    { value: 'STATE_BANK_OF_INDIA', type: 'STATE BANK OF INDIA' },
    { value: 'AXIS_BANK', type: 'AXIS BANK' },
    { value: 'CASH_SALARY', type: 'CASH SALARY' },
    { value: 'CHEQUE_SALARY', type: 'CHEQUE SALARY' }
];

export const CITIES = [
    { value: '', type: 'Select City' },
    { value: 'MUMBAI', type: 'MUMBAI' },
    { value: 'DELHI_NCR', type: 'DELHI/NCR' },
    { value: 'AHMEDABAD', type: 'AHMEDABAD' },
    { value: 'BENGALURU', type: 'BENGALURU' },
    { value: 'PUNE', type: 'PUNE' },
    { value: 'CHENNAI', type: 'CHENNAI' }
];