import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CommonResource {
  constructor(
    protected http: HttpClient,
  ) {

  }

  post(url: string, entity: any, options: any = {}) {
    return new Promise((resolve, reject) => {
      this.http.post(url, entity).subscribe((response: any) => {
        resolve(response ? response.data : {});
      }, reject);
    });
  }
  query(url: string, params: any) {
    return new Promise((resolve, reject) => {
      this.http.get(url, { params }).subscribe((response: any) => {
        resolve(response ? response.data : {});
      }, reject);
    });
  }
  get(url: string, entityId: string = null) {
    let params: any = new HttpParams();
    if (entityId) {
      params.set('id', entityId);
    }
    return new Promise((resolve, reject) => {
      this.http.get(url, { params }).subscribe((response: any) => {
        resolve(response ? response.data : {});
      }, reject);
    });
  }
  put(url: string, entity: object) {
    return new Promise((resolve, reject) => {
      this.http.put(url, entity).subscribe((response: any) => {
        resolve(response ? response.data : {});
      }, reject);
    });
  }
  patch(url: string, entity: object) {
    return new Promise((resolve, reject) => {
      this.http.patch(url, entity).subscribe((response: any) => {
        resolve(response ? response.data : {});
      }, reject);
    });
  }
  delete(url: string, data: any) {
    return new Promise((resolve, reject) => {
      this.http
        .delete(url, { params: data })
        .subscribe((response: any) => {
          resolve(response.data);
        }, reject);
    });
  }
}
