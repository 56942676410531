import { INavData } from '@coreui/angular';
import { LayoutSettings } from '../services';
export const ReferalSettings: LayoutSettings = {
  links: {
    profile: {
      text: 'Profile',
      href: '/app/referal/profile'
    },
    dashboard: {
      text: 'Dashboard',
      href: '/app/referal/dashboard'
    },
    settings: {
      text: 'Document',
      href: '/app/referal/document'
    },
  }
};
export const ReferalNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/app/referal',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Refer',
    url: '/app/referal/refer',
    icon: 'icon-people'
  },
  {
    name: 'Profile',
    url: '/app/referal/profile',
    icon: 'icon-pencil'
  },
];
