import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services';
@Component({
  selector: 'app-401',
  templateUrl: './401.component.html',
})
export class P401Component implements OnInit {

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  back() {
    console.log(this.authService.loginRedirect());
  }
}
