import { Component, OnInit, OnDestroy, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { SubSink } from 'subsink';
import { LayoutService, AuthService, LayoutSettings } from '@modules/share/services';
import { INavData } from '@coreui/angular';
@Component({
  selector: 'app-layout',
  templateUrl: './app-layout.component.html',
})

export class AppLayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  public sidebarMinimized = false;
  subscription = new SubSink();
  public navItems: INavData[];
  public settings: LayoutSettings;

  constructor(
    private layoutService: LayoutService,
    private authService: AuthService,
    private ref: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.subscription.add(
      this.layoutService.$navItemsList.subscribe((navItems: INavData[]) => {
        if (navItems.length) {
          this.navItems = navItems;
          this.ref.detectChanges();
        }
      }),
      this.layoutService.$settingsList.subscribe((settings: LayoutSettings) => {
        if (settings) {
          this.settings = settings;
          this.ref.detectChanges();
        }
      }),
    );
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    this.authService.logout();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
