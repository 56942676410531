import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthModule } from './auth.module';
import { ApplyLoneComponent, SignupComponent, ForgetPasswordComponent, LoginComponent } from './component';
import { NoAuthGuard } from '../share/guards';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: 'signup',
    component: SignupComponent,
    data: {
      title: 'Signup Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: 'forgetPassword',
    component: ForgetPasswordComponent,
    data: {
      title: 'Forget Password Page'
    },
    canActivate: [NoAuthGuard]
  },
  {
    path: 'applyLoan',
    component: ApplyLoneComponent,
    data: {
      title: 'Apply FOr Loan'
    },
    canActivate: [NoAuthGuard]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule { }
