import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class FormService {

  constructor() { }

  public  markDirty(step: any) {
    for (let control in step) {
      step[control].markAsDirty();
    }
  } 


public  getErrors(control: FormControl, name: string) {
    // console.log(control.errors);
    
    if (control.errors.required) {
      return name + ' is required!';
    }
    
    if (control.errors.pattern) {
      if (name == 'Loan Ammount' || name == 'Monthly Salary' || name == 'Pincode') {
        return name + ' should contains numbers only!';
      }

      if (name == 'Email') {
      return name + ' is invalid!';
      }
      
      if (name == 'Mobile No.') {
        return name + ' should not contains space and other special characters!';
      }
      
      return name + ' input pattern not matched!';
    }

    if (control.errors.email) {
      return name + ' is invalid!';
    }

    
    if (control.errors.maxlength) {
      return 'Max length for '+name + ' is '+control.errors.maxlength.requiredLength+' !';
    }
    if (control.errors.minlength) {
      return name + ' length should be '+control.errors.minlength.requiredLength +' !';
    }

    if (control.errors.min) {
      return 'Minimum amount for '+name + ' is '+control.errors.min.min+' !';
    }
    
    if (control.errors.max) {
      return 'Maximum amount for '+name + ' is '+control.errors.max.max+' !';
    }
    return 'Field ' + name + ' having errors!'
  }
}
