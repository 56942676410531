import { INavData } from '@coreui/angular';
import { LayoutSettings } from '../services';
export const BackendSettings: LayoutSettings = {
  links: {
    profile: {
      text: 'Profile',
      href: '/app/backend/profile'
    },
    dashboard: {
      text: 'Dashboard',
      href: '/app/backend/dashboard'
    },
    settings: {
      text: 'Document',
      href: '/app/backend/document'
    },
  }
};
export const BackendNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/app/backend',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Refer',
    url: '/app/backend/refer',
    icon: 'icon-people'
  },
  {
    name: 'Followup',
    url: '/app/backend/followup',
    icon: 'icon-people'
  },
  {
    name: 'Profile',
    url: '/app/backend/profile',
    icon: 'icon-pencil'
  },
];
