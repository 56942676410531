import { NgModule } from '@angular/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AlertModule } from 'ngx-bootstrap/alert';
import { MainRoutingModule } from './main.routing';
import { CommonResource } from './share/services';
import { RequestInterceptorService } from './share/interceptors';
import {ProfileService} from './common/services';
import {DataTablesModule} from 'angular-datatables';


import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { P404Component, AppLayoutComponent, P401Component } from './share/components';
import { LoaderComponent } from './share/components/loader/loader.component';

@NgModule({
  declarations: [
    P404Component,
    AppLayoutComponent,
    P401Component,
    LoaderComponent,
  ],
  imports: [
    MainRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    BsDropdownModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    TabsModule.forRoot(),
    ChartsModule,
    FormsModule,
    HttpClientModule,
    AlertModule,
    ReactiveFormsModule,
    DataTablesModule
  ],
  exports: [LoaderComponent],
  providers: [
    ProfileService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
  ]
})
export class MainModule { }
