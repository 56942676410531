import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../services';
import { RouterStateSnapshot } from '@angular/router';
import { ProfileService } from '../../../common/services';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public router: Router,
    public storageService: StorageService,
    public profileService: ProfileService) { }

  public get token() {
    return this.storageService.getItem('token');
  }

  public setToken(token: string) {
    this.storageService.setItem('token', token);
  }

  public checkAuth(state: RouterStateSnapshot) {
    const user: any = this.storageService.getItem('userInfo');
    //console.log(user,state.url);
    if (user && user.user_role) {
      switch (user.user_role) {
        case UserTypes.USER: {
          if (state.url.indexOf('app/user/') >= 0) {
            return true;
          } else {
            this.router.navigate(['app/unauthorized']);
          }
          break;
        }
        case UserTypes.ADMIN: {
          if (state.url.indexOf('app/admin/') >= 0) {
            return true;
          } else {
            this.router.navigate(['app/unauthorized']);
          }
          break;
        }
        case UserTypes.BACKEND: {
          if (state.url.indexOf('app/backend/') >= 0) {
            return true;
          } else {
            this.router.navigate(['app/unauthorized']);
          }
          break;
        }
        case UserTypes.REFERAL: {
          if (state.url.indexOf('app/referal/') >= 0) {
            return true;
          } else {
            this.router.navigate(['app/unauthorized']);
          }
          break;
        }
      }
    } else {
      this.logout();
    }
    return false;
  }

  public loginRedirect() {
    const hasToken = this.token;
    if (hasToken) {
      const user = this.storageService.getItem('userInfo');
      switch (user.user_role) {
        case UserTypes.USER: {
          this.router.navigate(['app/user/']);
          return false;
        }
        case UserTypes.ADMIN: {
          this.router.navigate(['app/admin/']);
          return false;
        }
        case UserTypes.BACKEND: {
          this.router.navigate(['app/backend/']);
          return false;
        }
        case UserTypes.REFERAL: {
          this.router.navigate(['app/referal']);
          return false;
        }
        default: {
          return true;
        }
      }
    } else {
      this.logout();
    }
  }

  public logout() {
    localStorage.clear();
    this.profileService.updateProfile(null);
    this.router.navigate(['auth/login']);
  }

}

export const UserTypeList: Array<string> = ['USER', 'ADMIN', 'REFERAL', 'BACKEND'];

export abstract class UserTypes {
  public static readonly ADMIN = 'ADMIN';
  public static readonly REFERAL = 'REFERAL';
  public static readonly BACKEND = 'BACKEND';
  public static readonly USER: string = 'USER';
  public static readonly LEAD: string = 'LEAD';
};


export abstract class LoanStatus {
  public static readonly INITIATED: string = 'INITIATED';
  public static readonly FILE_LOGIN: string = 'FILE_LOGIN';
  public static readonly DOCUMENT_PENDING: string = 'DOCUMENT_PENDING';
  public static readonly DOCUMENT_UPLOADED: string = 'DOCUMENT_UPLOADED';
  public static readonly APPROVED: string = 'APPROVED';
  public static readonly REJECTED: string = 'REJECTED';
  public static readonly DISBURSED: string = 'DISBURSED';
  public static readonly NOT_INTERESTED: string = 'NOT_INTERESTED';
};