import { INavData } from '@coreui/angular';
import { LayoutSettings } from '../services';
export const AdminSettings: LayoutSettings = {
  links: {
    profile: {
      text: 'Profile',
      href: '/app/admin/profile'
    },
    dashboard: {
      text: 'Dashboard',
      href: '/app/admin/dashboard'
    },
    settings: {
      text: 'Document',
      href: '/app/admin/document'
    },
  }
};
export const AdminNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/app/admin',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Refer',
    url: '/app/admin/refer',
    icon: 'icon-people'
  },
  {
    name: 'Backend',
    url: '/app/admin/backend',
    icon: 'icon-people'
  },
  {
    name: 'Profile',
    url: '/app/admin/profile',
    icon: 'icon-pencil'
  }, 
  {
    name: 'Add Disbusrsed Loan',
    url: '/app/admin/disbursed-order',
    icon: 'icon-plus'
  }
];
