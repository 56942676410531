import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AlertConfig } from 'ngx-bootstrap/alert';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  public alertSubject = new Subject<AlertMessage>();

  constructor() {

  }

  public showAlert(message: AlertMessage) {
    this.alertSubject.next(message);
  }

  public hideAlert() {
    this.alertSubject.next({
      message: '',
      type: '',
      timeout: 1000,
      dismissible: true
    });
  }

}

export function getAlertConfig(): AlertConfig {
  return Object.assign(new AlertConfig(), { type: 'success' });
}


export interface AlertMessage {
  message: string;
  type: string;
  timeout: number;
  dismissible: boolean;
}


export abstract class AlertTypes {
  public static readonly SUCCESS: string = 'success';
  public static readonly INFO: string = 'info';
  public static readonly WARNING: string = 'warning';
  public static readonly DANGER: string = 'danger';
}