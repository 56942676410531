import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { COMPANY_TYPES, LOAN_TYPES, GENDERS, SALARY_BANKS, CITIES } from '@modules/share/lib/signup-constant';
import { CommonResource } from '../../../share/services/common/common.resource';
import { AppEndPoint } from '../../../share/lib/api-constant';
import { FormService } from '../../../share/services';
import { AuthService, StorageService, AlertService, AlertTypes } from '../../../share/services';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';

function validateBank(control: AbstractControl) {
  if (control.value === 'CASH_SALARY' || control.value === 'CHEQUE_SALARY') {
    return { invalidBank: true };
  }
  return null;
}

@Component({
  selector: 'app-apply-loan',
  templateUrl: './applyLoan.component.html',
  styleUrls: ['./applyLoan.component.scss']
})
export class ApplyLoneComponent implements OnInit {
  step1: FormGroup;
  step2: FormGroup;
  step3: FormGroup;
  isEditable = false;
  values:any;
  val: any;

  isInvalidBankSelected = null;

  companyTypes: Array<any> = COMPANY_TYPES;
  loanTypes: Array<any> = LOAN_TYPES;
  genders: Array<any> = GENDERS;
  salaryBanks: Array<any> = SALARY_BANKS;
  cities: Array<any> = CITIES;
  other_bank = false;
  other_cities = false;

  isBackNavAllowed = true;

  constructor(
    private _formBuilder: FormBuilder,
    private commonResource: CommonResource,
    private authService: AuthService,
    private storageService: StorageService,
    private alertService: AlertService,
    public formService: FormService,
    public router: Router
  ) { }

  @ViewChild('stepper') stepper: MatStepper;

  get s1() { return this.step1.controls; }
  get s2() { return this.step2.controls; }
  get s3() { return this.step3.controls; }

  ngOnInit(): void {
    this.step1 = this._formBuilder.group({
      loanType: ['', Validators.required],
      ammount: ['', [Validators.required, Validators.min(40000), Validators.max(10000000), Validators.pattern(`^[0-9]*$`)]],
      gender: ['', Validators.required],
      city: ['', Validators.required],
      other_city: ''
    });
    this.step1.get('city').valueChanges.subscribe(values => {
      if( values === 'others' ) {
        this.step1.get('other_city').setValidators(Validators.required);
        this.step1.get('other_city').updateValueAndValidity()
        }
        else { 
        this.step1.get('other_city').clearValidators();
        this.step1.get('other_city').updateValueAndValidity()
        }
    });

    this.step2 = this._formBuilder.group({
      companyName: ['', Validators.required],
      companyType: ['', Validators.required],
      monthlySalary: ['', [Validators.required, Validators.min(18000), Validators.pattern(`^[0-9]*$`)]],
      salaryBank: ['', [Validators.required, validateBank]],
      other_salaryBank: ''
    });
    this.step2.get('salaryBank').valueChanges.subscribe(val => {

      if(val === 'CASH_SALARY' || val === 'CHEQUE_SALARY') {
        this.isInvalidBankSelected = true;
      } else {
        this.isInvalidBankSelected = null;
      }

      if( val === 'other') {
        this.step2.get('other_salaryBank').setValidators(Validators.required);
        this.step2.get('other_salaryBank').updateValueAndValidity()
        }
        else { 
        this.step2.get('other_salaryBank').clearValidators();
        this.step2.get('other_salaryBank').updateValueAndValidity()
        }
    });


    this.step3 = this._formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      DOB: ['', Validators.required],
      pincode: ['', [Validators.required, Validators.maxLength(6), Validators.pattern('^[0-9]*$')]],
      mobileNo: ['', [Validators.required, Validators.maxLength(10
      ), Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
      address: [''],
      loanApplied: ['', Validators.required]
    });
    this.isInvalidBankSelected = null;
  }

  showInput(data){
    if(data === 'other'){
      this.other_bank = true;
    }
    else{
        this.other_bank = false;
    }
  }
  showCity(data1){
    if(data1 === 'others'){
      this.other_cities = true;
      }
    else{
        this.other_cities = false;
    }
  }



  public signup(stepper: MatStepper) {
    this.step3.get('loanApplied').clearValidators();
    this.step3.get('loanApplied').updateValueAndValidity();
    if (this.step1.invalid || this.step2.invalid || this.step3.invalid) {
      return;
    }
    this.step3.get('loanApplied').setValidators(Validators.required);
    this.step3.get('loanApplied').updateValueAndValidity();
    let s1form = this.step1.getRawValue();
    if(s1form.other_city !==''){
      s1form.city = s1form.other_city;
      delete s1form.other_city;
    }
    let s2form = this.step2.getRawValue();
    if(s2form.other_salaryBank !==''){
      s2form.salaryBank = s2form.other_salaryBank;
      delete s2form.other_salaryBank;
    }
    
    let s3form = this.step3.getRawValue();
    delete s3form.loanApplied;

    const details = {
      ...s1form,
      ...s2form,
      ...s3form
    }

    details['userRole'] = 'USER';
    details['isloginallowed'] = 'Y';
    this.commonResource.post(AppEndPoint.USERS + 'signup', details).then((data: any) => {

      this.alertService.showAlert({
        message: 'Apply for loan successfully !',
        timeout: 3000,
        type: AlertTypes.SUCCESS,
        dismissible: true
      });
      this.step3.get('loanApplied').clearValidators();
      this.step3.get('loanApplied').updateValueAndValidity();
      this.stepper.selected.completed = true;
      stepper.next();
      this.setNavigaion();
    }, (error: any) => {
      console.log(error);
    });
  }

  getMaxAllowedDate() {
    let oneYearFromNow = new Date();
    let mill = oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 18);
    let oldDate = new Date(mill);

    let year = oldDate.getFullYear();
    let month = (oldDate.getMonth() + 1) < 10 ? '0' + (oldDate.getMonth() + 1) : oldDate.getMonth() + 1;
    let day = oldDate.getDate() < 10 ? '0' + oldDate.getDate() : oldDate.getDate();

    let dateString = `${year}-${month}-${day}`;
    return dateString;
  }

  setNavigaion() {
    this.isBackNavAllowed = false;
  }

  setNavStepIndex(event: any) {
    if(event.selectedIndex === 3) {
      this.isBackNavAllowed = false;
    }
  }

}
