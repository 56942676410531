import { INavData } from '@coreui/angular';
import { LayoutSettings } from '../services';

export const UserNavItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/app/user',
    icon: 'icon-speedometer',
    badge: {
      variant: 'info',
      text: 'NEW'
    }
  },
  {
    name: 'Document',
    url: '/app/user/document/null',
    icon: 'icon-cloud-upload'
  },
  {
    name: 'Profile',
    url: '/app/user/profile',
    icon: 'icon-pencil'
  },
];

export const UserSettings: LayoutSettings = {
  links: {
    profile: {
      text: 'Profile',
      href: '/app/user/profile'
    },
    dashboard: {
      text: 'Dashboard',
      href: '/app/user/dashboard'
    },
    settings: {
      text: 'Document',
      href: '/app/user/document/null'
    },
  }
}