import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { INavData } from '@coreui/angular';
import { RouterStateSnapshot } from '@angular/router';
import { AdminNavItems, BackendNavItems, ReferalNavItems, UserNavItems } from '../../navs';
import { UserSettings, AdminSettings, ReferalSettings, BackendSettings } from '../../navs';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  private navItemsList: INavData[] = [];
  private navItemsPromise = new BehaviorSubject<Array<INavData>>(this.navItemsList);
  $navItemsList = this.navItemsPromise.asObservable();

  private settings: LayoutSettings = null;
  private settingsListPromise = new BehaviorSubject<LayoutSettings>(this.settings);
  $settingsList = this.settingsListPromise.asObservable();


  constructor() { }

  public updateNavList(state: RouterStateSnapshot) {
    if (state.url.indexOf('app/user/') >= 0) {
      this.navItemsPromise.next(UserNavItems);
    } else if (state.url.indexOf('app/admin/') >= 0) {
      this.navItemsPromise.next(AdminNavItems);
    } else if (state.url.indexOf('app/backend/') >= 0) {
      this.navItemsPromise.next(BackendNavItems);
    } else if (state.url.indexOf('app/referal/') >= 0) {
      this.navItemsPromise.next(ReferalNavItems);
    }
  }

  public updateSettings(state: RouterStateSnapshot) {
    if (state.url.indexOf('app/user/') >= 0) {
      this.settingsListPromise.next(UserSettings);
    } else if (state.url.indexOf('app/admin/') >= 0) {
      this.settingsListPromise.next(AdminSettings);
    } else if (state.url.indexOf('app/backend/') >= 0) {
      this.settingsListPromise.next(BackendSettings);
    } else if (state.url.indexOf('app/referal/') >= 0) {
      this.settingsListPromise.next(ReferalSettings);
    }
  }

}


export interface LayoutSettings {
  links: {
    dashboard: Links;
    profile: Links;
    settings: Links;
  }
}

export interface Links {
  text: string;
  href: string;
}

