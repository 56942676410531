import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent, SignupComponent, ApplyLoneComponent, ForgetPasswordComponent } from './component';
import { AuthRoutingModule } from './auth.routing';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonResource } from '../share/services';
import { RequestInterceptorService } from '../share/interceptors/request.intercepter';
import { MatStepperModule } from '@angular/material/stepper';

@NgModule({
  declarations: [LoginComponent, SignupComponent, ApplyLoneComponent, ForgetPasswordComponent],
  imports: [
    CommonModule,
    // BrowserAnimationsModule,
    HttpClientModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule
  ],
  providers: [
    CommonResource,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptorService,
      multi: true
    },
  ]
})
export class AuthModule { }
